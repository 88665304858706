import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from '@environments/environment';
import { getFirstResult } from '@utils';
import { SharedGenericAlertsComponent } from 'app/shared/components';
import * as moment from 'moment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxConfigService,
  MonkeyEcxCookieStorageService,
  MonkeyEcxUtils
} from 'monkey-front-core';
import { MonkeyStyleGuideModalFixedService } from 'monkey-style-guide';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericAlertsService extends MonkeyEcxCommonsService implements Resolve<any> {
  constructor(
    private cookieService: MonkeyEcxCookieStorageService,
    protected monkeyConfigService: MonkeyEcxConfigService,
    private modalService: MonkeyStyleGuideModalFixedService
  ) {
    super();
  }

  private onHandleSettingsCookie(params: { showedGenericAlertsSeller?: true }) {
    const { cookieService } = this;
    const cookie: string = cookieService.getCookie('monkey-app-settings');
    if (!cookie) {
      cookieService.setCookie(
        'monkey-app-settings',
        btoa(
          JSON.stringify({
            alerts: {
              ...params
            }
          })
        ),
        environment
      );
    } else {
      const decodedCookie = JSON.parse(atob(cookie));

      cookieService.setCookie(
        'monkey-app-settings',
        btoa(
          JSON.stringify({
            ...decodedCookie,
            alerts: {
              ...decodedCookie.alerts,
              ...params
            }
          })
        ),
        environment
      );
    }
  }

  async onHandleAlertByProgram() {
    const { __params, monkeyConfigService, cookieService } = this;
    const { companyType } = __params;

    const config = await monkeyConfigService.config().pipe(getFirstResult()).toPromise();

    const { internalAlert } = config;

    if (internalAlert) {
      const profiles = internalAlert.profiles || [];

      if (profiles.length > 0 && !profiles.includes(companyType)) return;

      const cookie: string = cookieService.getCookie('monkey-app-settings');
      const type = `showedGenericAlerts${MonkeyEcxUtils.capitalize(companyType)}`;
      let dontShow = false;
      try {
        dontShow = cookie && JSON.parse(atob(cookie))?.alerts?.[type];
      } catch (e) {
        // not to do
      }

      if (dontShow) return;

      const { img, title, message, endDate, noEndDate, action, hideClose } = internalAlert;

      if (moment().isSameOrBefore(moment(endDate)) || noEndDate) {
        this.modalService.open(SharedGenericAlertsComponent, {
          color: 'theme',
          size: 'md',
          data: {
            img,
            title,
            message,
            hideClose,
            action,
            callback: (dontShowAnymore: boolean) => {
              this.onHandleSettingsCookie({
                [`showedGenericAlerts${MonkeyEcxUtils.capitalize(companyType)}`]: dontShowAnymore
              });
            }
          }
        });
      }
    }
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    super.resolve(route, state, {
      callbackMain: () => {
        this.__params = {
          ...route.data,
          url: state.url
        };

        this.onHandleAlertByProgram();
      }
    });

    return null;
  }
}
